
@import "~lessRoot/_mixins.less";
@import "~lessRoot/_variables.less";

.selected-facet {
	margin: 0 0.94rem 0.65rem 0;

	.tablet-and-up-styles({
		margin: 0.4rem 0.94rem 0.4rem 0;
	});

	&:focus-within {
		border-color: @BB;
	}

	/deep/ .ui-chip {
		&__info {
			flex-direction: row;
		}

		&__label {
			font-weight: 500;
			font-size: 14px;
			color: #01305F;
			line-height: 1;

			.tablet-and-up-styles({
				&-colon {
					display: inline;
				}
			});
		}

		&__content {
			font-weight: 500;
			font-size: 14px;
			color: @BB;
			line-height: 1;
		}

		&__remove {
			button {
				color: @BB;
				line-height: 1rem;
			}
		}
	}
}
