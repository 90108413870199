
@import '../../../../../less/_mixins.less';

.promo-label {
	display: block;
	font-size: 0.75rem;
	font-weight: 500;

	.tablet-and-up-styles({
		font-size: 0.85rem;
	});
}

.lp-blue {
	color: @BB;
}

.lp-red {
	color: @DR;
}

.lp-green {
	color: @AAEME;
}

.lp-yellow {
	color: @AAOCH;
}

.lp-purple {
	color: @AAPER;
}
