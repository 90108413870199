
@import '../../../../less/_mixins.less';
@radioButtonSize: 20px;

.blains-radiobutton {
	display: flex;
	margin-top: 4px;

	label {
		cursor: pointer;
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-start;
		font-size: 1em;
		position: relative;
		user-select: none;
		text-decoration: none;

		input {
			cursor: pointer;
			height: 0;
			opacity: 0;
			position: absolute;
			width: 0;
		}

		span {
			padding: 0 0.25rem 0 0.5rem;
		}
	}

	.checkmark {
		position: relative;
		background-color: #ffffff;
		border: 1px solid @BB;
		border-radius: 10px;
		width: @radioButtonSize;
		height: @radioButtonSize;
		left: 0;
		flex-shrink: 0;
		top: 0;
		&.checked {
			border: 2px solid @BB;
		}
	}

	.checked.checkmark {
		background-color: #ffffff;
		&.disabled {
			opacity: 0.4;
		}
	}

	.checkmark::after {
		content: '';
		display: none;
		position: absolute;
	}

	label .checked.checkmark::after {
		display: block;
		height: 12px;
		left: 2px;
		top: 2px;
		width: 12px;
		background-color: @BB;
		border-radius: 6px;
	}
}

.blains-radiobutton:focus-within {
	label .checkmark,
	label .checked.checkmark {
		background-color: tint(@BB, 60%);
	}

	label .checked.checkmark::after {
		background-color: tint(@BB, 60%);
		border: 2px solid #ffffff;
	}
}

.blains-radiobutton.no-label {
	display: inline-block;

	label {
		padding-left: @radioButtonSize + 4px;
	}

	.checkmark {
		left: 0;
		top: 0;
	}
}
