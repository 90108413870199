
@import '../../../../less/_mixins.less';

.product-stock-info {
	font-size: 0.75rem;
	color: shade(@CL, 40%);
	line-height: .9rem;

	& > :not(:first-child) {
		margin-top: 0.3rem;
	}
}

.store-info {
	&__in-store-only {
		color: @blue-gray;
		font-weight: 400;
	}

	&__low-stock {
		.red {
			font-weight: 500;
			color: #AA141A;
		}
	}

	& > :not(:first-child) {
		margin-top: 0.3rem;
	}
}

.shipping-info__offer {
	color: @AAEME;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 500;

	.icon {
		font-size: 1rem;
		vertical-align: bottom;
	}
}

.stock-status {
	color: @AAEME;
	font-weight: 500;
}

