
@import "~lessRoot/_mixins.less";

.category-carousel {
	--list-slider-item-gap: 8px;

	.mobile-styles({
		margin-left: -10px; // to cancel out section#content's padding
		margin-right: -10px;
	});

	.tablet-styles({
		width: 100vw;
		margin-left: -15px;

		[data-list-slider-track] {
			padding-left: var(--list-slider-item-gap);
			&::after {
				display: inline-block;
				content: '';
				width: 10px;
				margin-left: calc(var(--list-slider-item-gap) * -1);
			}
		}
	});

	.desktop-min-only-styles({
		/* makes buttons align with product list header on non-DXP prod list pages */
		.product-list-head + & {
			margin-top: -30px;
		}
		--list-slider-item-gap: 10px;
	});

	&__buttons {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
		min-height: 46px;

		&:not(:has([data-list-slider-action]:not([aria-disabled="true"]))) {
			[data-list-slider-action] {
				display: none;
			}
		}
	}

	&__skip {
		color: @BB;
		font-size: 0.75rem;
		float: left;

		&--above {
			position: absolute;

			.desktop-min-only-styles({
				margin-top: 44px;
			});
		}

		&--below {
			position: absolute;
			z-index: 40; // prevents the focus outline from being cut off on small screens

			.desktop-min-only-styles({
				margin-top: 2px;
				z-index: auto;
			});
		}

		&:not(:focus) {
			.visually-hidden();
		}
	}

	&__track {
		margin: 14px auto 0 auto;
		display: flex;
		flex-wrap: nowrap;
		overflow-x: visible;
		align-items: stretch;
		height: 100%;
		padding-bottom: 7px;

		.desktop-min-only-styles({
			padding-bottom: 0;
		});
	}
}
