
@import "~lessRoot/_mixins.less";

.battery-product-summary {
	display: grid;
	grid-template-columns: 120px 1fr;
	gap: 15px;
	padding: 10px 0;
	margin: 0 10px;
	border-bottom: 1px solid tint(@CL, 60%);

	--battery-stat-value-line-height: 1.1;

	&--medium {
		--battery-header-brand-font-size: 16px;
		--battery-header-title-font-size: 22px;
		--battery-header-title-line-height: 1.36;

		--battery-stats-row-gap: 2px;
		--battery-stats-column-gap: 7px;
		--battery-stat-value-size: 28px;
		--battery-stat-value-line-height: 1.3;
		--battery-stat-label-size: 16px;

		grid-template-columns: 185fr 285fr;
		gap: 12px;
		padding: 15px 10px;
	}

	&--large {
		--battery-header-brand-font-size: 16px;
		--battery-header-title-font-size: 26px;
		--battery-header-title-line-height: 1.3;

		--battery-stats-row-gap: 6px;
		--battery-stats-column-gap: 7px;
		--battery-stat-value-size: 28px;
		--battery-stat-label-size: 16px;

		grid-template-columns: 230px 1fr 320px;
		gap: 15px;
		padding: 15px 10px;

		.styles-at(1200px,{
			grid-template-columns: 5fr 7fr;
		});
	}

	&__left {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__right {
		display: flex;
		flex-direction: column;
	}

	&--large &__right {
		display: contents;

		.styles-at(1200px,{
			display: flex;
			flex-direction: column;
		});
	}

	&__size {
		font-weight: 500;
		font-size: 12px;
		line-height: 1.16;
		margin: 10px 0;
	}

	&--medium &__size {
		margin-top: 4px;
		font-size: 1rem;
	}

	&--large &__size {
		font-size: 1rem;
	}

	&__see-details {
		display: none;
	}

	&--medium &__see-details {
		display: block;
		margin-top: 10px;
	}

	&--large &__see-details {
		display: block;
		margin-top: 15px;
	}

	&__meta {
		font-size: 12px;
		color: shade(@CL, 40%);
		line-height: 1.33;

		& > *:not(:last-child) {
			padding-right: 10px;
			margin-right: 10px;
			border-right: 1px solid currentColor;
		}
	}

	&__stats {
		margin: 6px 0 8px 0;
	}


	&__pricing-info {
		padding-left: 5px;
		border-left: 2px solid tint(@CL, 80%);
	}

	&__promo-container {
		display: grid;
		gap: 4px;
		margin: 10px 0 0;
	}

	&--large &__pricing-info {
		padding-left: 0;
		border-left: none;
	}
}

.battery-header {

	&__brand {
		text-decoration: none;
		font-size: 16px;
		line-height: 18px;
		font-weight: bold;
		color: #666666;
	}

	&__title-link {
		text-decoration: none;
		color: black;
	}

	&__title {
		font-size: 26px;
		line-height: 30px;
		font-weight: bold;
		margin: 0;
		letter-spacing: 0px;
		display: block;
	}

	.tablet-styles({
		&__brand {
			font-size: 12px;
			line-height: 18px;
		}

		&__title {
			font-size: 18px;
			line-height: 20px;
		}
	});
}
