
.vehicle-icon {
	&__body-path {
		fill: var(--vehicle-icon-color, #656E97);
		stroke-width: var(--vehicle-icon-stroke, 0);
		stroke: #656E97;
		stroke-linejoin: round;
		paint-order: stroke;
	}

	.cls-2 { fill: #333; }
	.cls-3 { fill: #4d4d4d; }
	.cls-4 { fill: #32374b; }
}
