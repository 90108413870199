
@import "~lessRoot/_mixins";
.swatch {
	height: 1rem;
	width: 1rem;
	border: 1px solid @blue-gray;
	border-radius: 100%;
	box-sizing: border-box;
	background-color: @blue-gray; // disguise subpixel rendering weirdness
	&-bg {
		height: 100%;
		width: 100%;
		border-radius: 100% - 1px;
	}
	&.active {
		border: 1px solid #01305F;
		padding: 2px;
		background-color: initial;
	}
}
