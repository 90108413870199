
@import "~lessRoot/_mixins";

.product-list {
	margin-bottom: 2rem;
}

/deep/ .product-list-grid {
    display: flex;
    flex-wrap: wrap;

    &__item-wrapper {
        width: 100%;
		margin: 0;
		padding: 1.25rem 0.9375rem;

        .desktop-min-only-styles({
            width: 25%;
            padding-bottom: 1.875rem;
        });
	}

	&__item {
		.tablet-styles({
            display: flex;
            width: 100%;
            & > .psc-image-section {
                width: 40%;
				min-width: 125px; // for very small screens; prevent flags from wrapping
            }

			& > .psc-content {
				width: 60%;
			}
        });
    }

	&__banner-insert {
		padding: 15px;
		border-top: 2px solid #e5e7f2;

		.tablet-and-up-styles({
			display: none;
		});
	}
}
