
@import '~lessRoot/_mixins.less';
@import "~lessRoot/_variables.less";

.sort-by {
	flex-shrink: 0;
	margin-right: 15px;

	.ui-btn {
		line-height: 1rem;

		&:hover:not(:disabled), &:focus {
			border-color: @BB;
		}
	}

	&__icon {
		font-size: 18px;
		vertical-align: middle;
		color: @BB;
	}

	&__label {
		font-weight: 700;
		color: #01305F;
		vertical-align: middle;
	}

	&__selected {
		font-weight: 500;
		color: @BB;
		vertical-align: middle;
	}

	.select__trigger {
		min-width: 150px;
		border-radius: 6px;

		padding: 10px 15px 10px 15px !important;
		font-size: 14px;
		color: @DB !important;
	}

	.select__options {
		background-color: white;
		padding: 10px;
		border-width: 0;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		z-index: 2;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

		&:focus, &:focus-visible {
			outline: none;
		}

		// overwrite some styling from UiSelect.vue
		.select__option {
			margin-bottom: 5px;

			+ .select__option {
				border-top: none;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&:hover {
				background: #E5F4FF;
			}

			&[aria-selected="true"] {
				background: inherit;

				&:hover {
					background: #E5F4FF;
				}
			}

			.sort-option {
				display: flex;
				gap: 5px;

				.icon {
					font-size: 16px;
				}

				.spacer {
					display: inline-block;
					width: 16px;
				}

				.title {
					font-size: 14px;
					padding-top: 2px;
				}

				&--active {
					.spacer, .icon {
						border-radius: 3px;
						outline: #95B2F5 solid 2px;
					}
				}
			}
		}
	}
}
