
@import '../../../../less/_mixins.less';
.checkbox-facet {
	margin-bottom: 0.7rem;
	color: @DB;
	font-size: 14px;
	padding: 3px 0 0 0;
	justify-content: space-between;

	.facet-total {
		margin: 4px 0 0 4px;
		color: #191B26;
	}
}
