
@import "~lessRoot/_mixins.less";

.selected-vehicle {

	&__inner {
		--saved-vehice-horizontal-icon-size: 117px;
		width: 100%;

		.tablet-and-up-styles({
			padding: 2px 0 4px 12px;
			border-left: 4px solid tint(@CL, 80%);
			margin-bottom: 20px;
		});
	}

	&__search.link-button {
		margin-top: 10px;
	}

	&__vehicle {
		border-left: 2px solid tint(@CL, 60%);
		padding-bottom: 4px;

		.tablet-and-up-styles({
			border-left: none;
			padding-bottom: 0;
			--metadata-row-alignment: column;
		})
	}

	&__label {
		font-size: 0.875rem;
		line-height: 1.35;
		color: shade(@CL, 40%);
		font-weight: 500;
		margin-bottom: 10px;
	}
}
