
@import '../../../../less/_mixins.less';
.product-facets-wrapper {
	flex-shrink: 0;
	width: 230px;

	.mobile-styles({
		width: 100%;
		margin: 0 auto 70px auto;
	});
}

.product-facets-tire-size-label {
	font-size: 0.875rem;
	line-height: 1.35;
	color: shade(@CL, 40%);
	font-weight: 500;
	margin-bottom: 10px;
}

.product-facets-tire-size {
	padding: 10px 0 6px 12px;
	border-left: 3px solid tint(@CL, 80%);
	margin-bottom: 20px;

	a {
		margin-top: 10px;
		font-size: 12px;
	}
}
