
@import '../../../../less/_mixins.less';
@checkboxSize: 1.25rem; // specified in rem so the checkboxes scale with font size

.blains-checkbox {
	a {
		cursor: pointer;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		font-size: 1em;
		line-height: 1.25;
		position: relative;
		user-select: none;
		text-decoration: none;
		padding-top: 4px;

		input {
			cursor: pointer;
			height: 0;
			opacity: 0;
			position: absolute;
			width: 0;
		}
	}

	span.link-label {
		padding-right: 0.25rem;
		color: #191B26;
		align-self: flex-end;
	}

	.checkmark {
		position: relative;
		background-color: #ffffff;
		border: 1px solid @BB !important;
		border-radius: 3px;
		width: @checkboxSize !important;
		height: @checkboxSize !important;
		font-size: 0.875rem;
		left: 0;
		flex-shrink: 0;
		top: 0;
		&.checked {
			border: 2px solid @BB;
		}
		margin-right: 0.5rem;
	}

	.checked.checkmark {
		background-color: @BB !important;
		color: white;
		&.disabled {
			opacity: 0.4;
		}
	}

	.icon-checkmark {
		display: block;
		padding: 0.125rem 0 0 0.125rem;
		color: white;
	}

	a .checkmark.indeterminate::after {
		display: block;
		height: 0.1875rem;
		width: 0.75rem;
		border-radius: 2px;
		background-color: @BB !important;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.blains-checkbox.no-label {
	display: inline-block;

	a {
		padding-left: @checkboxSize + 4px;
	}

	.checkmark {
		left: 0;
		top: 0;
	}
}
