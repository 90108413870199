
@import "~lessRoot/_mixins";

.psc-wrapper {
	display: flex;

	.desktop-min-only-styles({
		flex-direction: column;
		height: 100%;
	});
}

.psc-image-section {
	.tablet-styles({
		padding: 0 1rem 0 0;
	});

	.flag-top-left {
		position: absolute;
		left: 0;
		top: 0;
		display: block;

		.desktop-min-only-styles({
			display: inline-flex;
		});
	}
	.psc-image-link {
		display: block;
		position: relative;
		height: 0;
		padding-top: 100%;

		&__flag {
			padding-top: 100%;
		}
	}
	/deep/ .psc-image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		&__flag {
			height: 100%;
			top: 0;
		}
	}

	/deep/ .psc-image img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 0;
		transition: opacity .3s cubic-bezier(.55,0,.1,1);
		&.loaded {
			opacity: 1;
		}
	}
}

.mobile-swatches {
	margin-top: 1rem;
}

.psc-title {
	color: #000;
	font-size: 1rem;
	line-height: 1.25rem;
	margin: 0.625rem 0 0.9375rem 0;
	display: block;

	.mobile-styles({
		font-size: 0.875rem;
	});
}

.psc-sponsored {
	margin-bottom: 10px;
	color: @blue-gray;
	font-size: 0.75rem;
}

.psc-brand {
	color: #666;
	font-size: 0.8rem;
	font-weight: 500;
	display: block;
	margin-top: 0.7rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 370px;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}

	.mobile-styles({
		margin-top: 0;
	});

	.desktop-min-only-styles({
		font-size: 0.9rem;
		line-height: 1.3rem;
	})
}

.psc-pdp-link {
	text-decoration: none;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.psc-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-grow: 1;
}

.psc-content__flag {
	.mobile-styles({
		margin-bottom: 5px;
	});

	.tablet-and-up-styles({
		margin-top: 0.7rem;
	});
}

.psc-details {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 10px;
	flex-grow: 1;
}

.psc-options {
	font-size: 0.8rem;
	color: shade(@CL, 40%);

	.psc-option {
		white-space: nowrap;
		&:not(:first-child) {
			margin-top: 10px;
		}
	}
}

.price-container {
	display: flex;
	align-items: flex-end;

	.desktop-min-only-styles({
		margin-top: 10px;
	});
}

.reviews-container {
	/deep/ .review-stars {
		color: #985800;
	}

	/deep/ .reviews-wrapper {
		align-items: end;
	}

	/deep/ .reviews-amount {
		margin-left: 5px;
		font-size: 0.75rem;
		line-height: 1rem;
		color: @blue-gray;
	}
}

.atc-container {
	display: block;
	width: 100%;
	margin-top: 15px;
	align-self: flex-start;

	.atc-button {
		width: ~"min(160px, 100%)";
		padding: 10px 15px;
		font-size: 1rem;
		text-transform: uppercase;
	}

	.atc-icon {
		margin-right: 5px;
	}

	.faux-button {
		background-color: @DR;
		border: solid 2px @DR;
		color: #fff;
		opacity: 1;
		cursor: not-allowed;
	}
}
