
@import '../../../../../less/_mixins.less';

.pagination {
	display: flex;
	justify-content: center;
	margin-top: 1rem;
	padding-top: 1rem;

	.mobile-styles({
		align-items: center;
	});
}
.pagination-button {
	text-transform: uppercase;
	font-weight: 500 !important;
	max-width: 6rem !important;
	margin: 0 1rem;
	padding: 0.25rem 0.6rem !important;

	width: auto !important;

	.tablet-only-styles({
		border: none;
	});

	.desktop-min-only-styles({
		width: 100% !important;
		margin: 0 2rem;
		padding: 0 0.25rem !important;
	});
}
button[aria-disabled=true] {
	opacity: 0.5;

	.desktop-min-only-styles({
		border: none;

		&:hover {
			border: none;
			cursor: not-allowed;
		}
	});
}
/deep/ .icon {
	line-height: 1.5;
}
