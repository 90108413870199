
@import "../../../../../less/_mixins.less";
.detailed-loader-wrapper {
	width: 100% !important;
	flex: 1;

	.left, .right {
		width: 100%;
		height: 100%;
		flex: 1;
	}

	.left {
		padding-bottom: 1rem;
	}

	.right {
		justify-content: space-between;
		padding-left: 1rem;

		.size-sm {
			height: 10%;
			width: 90%;
		}

		.size-md {
			height: 20%;
			width: 90%;
		}
		.size-lg {
			height: 30%;
		}
	}
}
