
@import '~lessRoot/_mixins.less';

.saved-vehicle {
	display: grid;
	background-color: #FFFFFF;
	overflow: hidden;
	text-align: left;
	width: 100%;
	color: #000000;

	&__icon {
		grid-area: icon;
	}

	&__content {
		grid-area: content;
	}

	&__body {
		grid-area: body;
	}

	&__footer {
		grid-area: footer;
	}

	&--horizontal {
		grid-template-areas: "icon content";
		grid-template-columns: var(--saved-vehice-horizontal-icon-size, 120px) 1fr;
		align-items: center;
		gap: 10px;

		.mobile-styles({
			gap: 8px;
		});

		.ms-styles({
			gap: 0;
			grid-template-columns: var(--saved-vehice-horizontal-icon-size, 90px) 1fr;
		});
	}

	&--no-icon {
		grid-template-areas: "content";
		grid-template-columns: 1fr;
	}

	&--no-icon &__icon {
		display: none;
	}

	&--vertical {
		grid-template-areas: "icon"
		                     "content";
		grid-template-columns: 1fr;
	}

	&--vertical &__footer {
		margin-top: 6px;
	}

	&--hybrid {
		grid-template-areas: "icon   body"
		                     "footer footer";
		grid-template-columns: var(--saved-vehice-horizontal-icon-size, 84px) 1fr;
		align-items: center;
		column-gap: 12px;
	}

	&--hybrid &__content {
		display: contents;
	}

	&--hybrid &__footer {
		padding-left: 8px;
	}

	.leading-1em {
		line-height: 1em;
	}

	.link-button {
		font-size: 12px;
	}

	&--horizontal &__icon,
	&--hybrid &__icon {
		overflow: hidden;

		svg {
			width: 160%;
			transform: translateX(-40%);
		}
	}

	&--vertical &__icon {
		width: 100%;
		max-width: 150px;
		margin-bottom: 10px;
	}

	&--scheduler {
		grid-template-areas: "icon content";
		grid-template-columns: 72px 1fr;
		align-items: center;



		.mobile-styles({
			gap: 8px;
		});

		.ms-styles({
			gap: 0;
			grid-template-columns: 72px 1fr;
		});
	}

	&--scheduler &__body {
		display: flex;
		align-items: center;

		.saved-vehicle__line2 {
			padding-left: 6px;
			flex: 1;
		}
	}

	&__line1 {
		font-size: 1rem;
		line-height: 1.3125;
		font-weight: bold;
		margin-bottom: 2px;

		.tablet-and-up-styles({
			font-size: 1.125rem;
			line-height: 1.33;
		});
	}

	&__line2 {
		font-size: 0.8125rem;
		line-height: 1.35;
		text-transform: uppercase;
		font-weight: 500;
		margin-bottom: 0;

		.tablet-and-up-styles({
			font-size: 1rem;
			line-height: 1.33;
		});
	}

	&__trim {
		display: flex;
		color: shade(@CL, 40%);
		font-weight: 500;
		line-height: 1.35;
		margin-top: 2px;
		text-transform: uppercase;

		& > * {
			display: flex;
			&:not(:first-child)::before {
				content: '';
				width: 1px;
				margin: 0 8px;
				background: tint(@CL, 20%);

				.tablet-and-up-styles({
					margin: 0 10px;
				});
			}
		}
	}

	.ms-styles({
		&__description {
			font-size: 14px;
		}

		&__name {
			font-size: 16px;
		}

		&__trim {
			font-size: 11px;
		}
	})
}
