
@import "~lessRoot/_mixins.less";

.auto-image {
	display: block;
	padding-bottom: 100%;
	position: relative;
	margin-bottom: 15px;
	width: 100%;

	&__image {
		position: absolute;
		inset: 0;
		height: 100%;
		width: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			opacity: 0;
			transition: opacity .3s cubic-bezier(.55,0,.1,1);
			&.loaded {
				opacity: 1;
			}
		}
	}
}
