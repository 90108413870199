
@import '../../../less/_mixins.less';
@transitionTime: 300ms;

.blain-form-switch {

	label {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.toggle-background {
		transition: transform @transitionTime;
		position: relative;
		background-color: #8C93B2;
		border-radius: 14px;
		width: 48px;
		height: 28px;

		.ms-styles({
			width: 40px;
			height: 24px;
		});
	}

	.toggle-circle {
		transition: transform @transitionTime;
		position: absolute;
		border-radius: 50%;
		background-color: white;
		left: 2px;
		top: 2px;
		bottom: 2px;
		width: 24px;

		.ms-styles({
			width: 20px;
		});
	}

	.switch-label {
		font: normal normal bold 1rem/1.36 Roboto;
		margin-left: 6px;

		.ms-styles({
			font-size: 0.875rem;
		});
	}

	.checked {
		.toggle-background {
			background-color: #2079B0;
		}

		.toggle-circle {
			transform: translateX(20px);

			.ms-styles({
				transform: translateX(16px);
			});
		}
	}

}
