
@import "~lessRoot/_mixins.less";

.carousel-item {
	display: block;
	margin: 0;
	padding-bottom: 1px;
	width: 162px;

	&focus, &:focus-visible {
		outline: none;
	}

	.desktop-min-only-styles({
		width: 132px;
	});

	.carousel-card {
		display: grid;
		align-items: center;
		border-radius: 8px;
		gap: 6px;
		grid-template-columns: 48px minmax(auto, 96px);
		padding: 5px;
		margin: 0;
		text-align: center;
		border: 1px solid #cbd0e4;
		box-shadow: 0 0 6px #0000001a;
		color: #01305F;
		cursor: pointer;
		height: 100%;
		width: 100%;
		text-decoration: none;

		.desktop-min-only-styles({
			align-items: start;
			grid-template-columns: 1fr;
			grid-template-rows: min-content auto;
			padding-bottom: 7px;
		});

		&:focus, &:focus-visible, &:focus-within, &:hover {
			border: 2px solid #0054A4;
			padding: 4px;
			outline: none;

			.desktop-min-only-styles({
				padding-bottom: 6px;
			});
		}

		.category-img {
			display: block;
			padding-bottom: 100%;
			position: relative;

			img {
				height: 100%;
  				inset: 0;
				object-fit: contain;
				position: absolute;
				width: 100%;
				max-width: 100%;
			}
		}

		.category-title {
			padding: 1.5px 8px 1.5px 0;
			color: #01305F;
			font-size: .875rem;
			font-weight: 500;
			line-height: 1.1429;
			text-decoration: none;
			text-align: left;
			margin-bottom: 0;

			.desktop-min-only-styles({
				padding-right: 0;
			});

			.tablet-and-up-styles({
				text-align: center;
			});
		}
	}
}
