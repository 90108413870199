
@import "~lessRoot/_mixins.less";

.auto-stats {
	display: flex;
	flex-wrap: wrap;
	font-size: 24px;
	line-height: 25px;
	color: shade(@CL, 60%);
	margin: 10px 0;

	> div {
		padding-right: 15px;
		margin-bottom: 15px;
	}

	span {
		font-size: 16px;
		line-height: 1;
		text-transform: uppercase;
		display: block;
		white-space: nowrap;
	}

	.styles-at(1200px,{
		flex-direction: column;
		font-size: 16px;
		line-height: 20px;

		span {
			font-size: 11px;
			display: inline;
		}

		> div {
			padding-right: 0;
			margin: 0;
		}
	});
}


// .tire-stats {
// 	display: grid;
// 	row-gap: var(--tire-stats-row-gap, 2px);

// 	.tire-stat {
// 		display: flex;
// 		align-items: center;
// 		color: shade(@CL, 60%);

// 		&__value {
// 			font-size: var(--tire-stat-value-size, 16px);
// 			line-height: var(--tire-stat-value-line-height, 1.3);
// 			text-transform: lowercase;
// 			font-weight: 500;
// 		}

// 		&__label {
// 			font-size: var(--tire-stat-label-size, 12px);
// 			font-family: 'Roboto Condensed';
// 			line-height: 1.17;
// 			margin-left: var(--tire-stats-column-gap, 4px);
// 			text-transform: uppercase;
// 		}
// 	}
// }

