
@import '~lessRoot/_mixins.less';
@import '~lessRoot/_variables.less';

.my-store {
	background: tint(@CL, 90%);
	padding: 20px 0.625rem;
	display: flex;

	.tablet-and-up-styles({
		background: none;
	});

	&__checkbox {
		/deep/ label {
			height: auto !important;
			padding-left: 0 !important;

			span {
				padding-left: 0 !important;
				border-color: @BB;
			}
		}

		/deep/ .checkmark:not(.checked):not(.disabled):not(.error) {
			background-color: white;
		}

		/deep/ .checkmark.checked:not(.disabled):not(.error) {
			background-color: @BB;
		}

		/deep/ label:hover .checkmark:not(.disabled) {
			outline-color: tint(@BB, 60%);
		}
	}

	&__label {
		display: block;
		color: #191B26;
		font-weight: 500;
		font-size: 16px;
		line-height: 1.25;
	}

	&__name {
		color: #191B26;
		font-size: 13px;
		font-weight: 400;
		line-height: 1.25;
	}

	&__edit-button {
		.button-reset();
		font-size: 13px;
		font-weight: 400;
		color: @BB;
		text-decoration: underline;
		flex-shrink: 0;
		margin-left: 0.25rem;
	}
}
