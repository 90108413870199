
@import '~lessRoot/_variables.less';
@import '~lessRoot/_mixins.less';

.filter-header-top {
	display: flex;
	padding: 10px 12px 4px 12px;

	align-items: flex-start;
	background: #fff;

	&__filters {
		display: flex;
		flex-wrap: wrap;
		flex: 1;
	}

	.sort-by {
		margin-top: 0;
		margin-bottom: 0;

		.tablet-and-up-styles({
			margin-top: 0.4rem;
			margin-bottom: 0.4rem;
		});
	}

	.clear-all {
		margin: 0.4rem 1rem;

		.tablet-only-styles({
			margin-left: auto;
			padding-left: 0;
		});

		.desktop-min-only-styles({
			margin-top: 1.25rem;
			align-self: flex-start;
		});

		.tablet-and-up-styles({
			margin-left: auto;
			margin-right: 0;
			padding-right: 0;
		});
	}

	.tablet-and-up-styles({
		padding: 0;
	});

	//.desktop-min-only-styles({
	//	align-items: center;
	//});

	.with-separator {
		padding-right: 1.25rem;
		border-right: 2px solid tint(@CL, 60%);
	}
}

.filter-button {
	padding: 0.4rem !important;
	font-size: 0.9rem !important;
	width: auto !important;
	color: #01305F !important;

	margin: 0 0.75rem 0 0;
	font-weight: 500;
	text-transform: uppercase;

	.desktop-min-only-styles({
		margin-top: 0.75rem !important;
	});

	&.ui-btn {
		&:hover:not(:disabled), &:focus {
			border-color: @BB;
		}
	}
}
