
@import "~lessRoot/_mixins";
.swatch-wrapper {
	.btn-show-swatches {
		.button-reset();
		color: shade(@CL, 20%);
		font-size: 0.75rem;
		font-weight: 500;

		.mobile-styles({
			&__more-label {
				display: none;
			}
		});
	}
}
.swatches {
	display: flex;
	align-items: center;
}
.swatch-button {
	.button-reset();
	margin-right: 0.6rem;
}
.swatch-dropdown-wrapper {
	display: flex;
	flex-direction: column;
	background: #fff;
	border-radius: 8px;
	height: 180px;
	overflow-y: scroll;
}
.swatch-dropdown-button {
	.button-reset();

	display: flex;
	align-items: center;
	padding: 0.625rem;
	font-size: 0.75rem;
	color: shade(@CL, 40%);

	&:hover {
		background: tint(@CL, 90%);
	}

	&__label {
		display: inline-block;
		text-align: left;
		margin-left: 0.75rem;
		color: #191B26;
		line-height: 0.9375rem;
	}

	/deep/ .swatch {
		height: 1.25rem;
		width: 1.25rem;
		flex-shrink: 0;
	}
}
.nowrap {
	white-space: nowrap;
}

/deep/ .blain-dropdown,
/deep/ .dropdown-menu {
	background: transparent;
	border-radius: 8px;
	height: 200px;
}

/deep/ .blain-dropdown {
	padding: 0.625rem;
	height: 200px;
	background-color: #fff;
	z-index: 3; // make it go behind the filter header when scrolling, especially on mobile
}

/deep/ .blain-dropdown.shadow,
/deep/ .blain-dropdown-arrow {
	box-shadow: 0 0 0 2px @CL;
}
