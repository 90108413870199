
@import '~lessRoot/_mixins.less';

.tire-product-promo {
	color: var(--tire-product-promo-color);
	font-size: 12px;
	line-height: 1.16;

	&__label {
		display: flex;
		align-items: flex-end;
		font-weight: bold;
		text-transform: uppercase;

		.icon {
			margin-right: 2px;
			font-size: 14px;
		}
	}

	&__description {
		font-weight: 500;
	}
}
