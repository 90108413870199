
@import '~lessRoot/_mixins.less';

.battery-product-promo {
	color: var(--battery-product-promo-color);
	font-size: 12px;
	line-height: 1.16;

	&__description {
		font-weight: 500;

		.icon {
			font-size: 14px;
			vertical-align: middle;
		}
	}

	&__fineprint {
		font-size: 11px;
	}
}
