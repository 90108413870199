
@import '../../less/_mixins.less';

.ui-chip {
	flex: 0 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	border: 2px solid tint(@CL, 60%);
	margin: 0;
	padding: 0.4rem 0;
	background: tint(@CL, 90%);

	.tablet-and-up-styles({
		padding: 0.25rem 0;
	});
}

.ui-chip__info {
	display: flex;
	align-items: center;
	padding-left: 0.5rem;
	color: @DB;
	.tablet-and-up-styles({
		flex-direction: column;
		align-items: flex-start;
	});
}

.ui-chip__label {
	margin-right: 5px;
	.tablet-and-up-styles({
		color: shade(@CL, 20%);
		font-size: 0.7rem;
		font-weight: 500;
		line-height: 1.1;

		&-colon {
			display: none;
		}
	});
}

.ui-chip__content {
	font-size: 0.9rem;
	.tablet-and-up-styles({
		line-height: 1rem;
	});
}

.ui-chip__remove button {
	border: none;
	background: none;
	appearance: none;
	outline: none;
	font-weight: lighter;
	font-size: 1.5rem;
	margin: 0;
	padding: 0 0.5rem;
	color: @LB;
	line-height: 0.5;


	.tablet-and-up-styles({
		line-height: 0.65;
	});
}
