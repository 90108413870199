
@import '~lessRoot/_mixins.less';
@import '~lessRoot/_variables.less';

/deep/ .search-box-wrapper {
	.blains-search-bar {
		background: none;
	}

	.blains-input-wrapper {
		padding: 0 10px;

		.blains-input {
			border: 1px solid #717BA9;
			border-radius: 5px;

			.icon-search {
				color: @BB;
			}
			input {
				font-size: 14px;

				.mobile-styles({
					font-size: 16px;
				});
			}
		}
	}
}

.facets-container {
	position: relative;
	padding: 0 0.625rem;

	&--scrollable {
		padding-left: 0;
	}

	&__inner {
		&--scrollable {
			.custom-scrollbar(@DB);

			overflow-y: scroll;
			// putting padding on the wrapper element causes the checkbox hover outline to be cut off
			padding-left: 0.625rem;

			&:after {
				content: '';
				display: block;
				height: 1rem;
				width: 100%;
				background: transparent;
			}
		}
	}

	&--scrollable:after {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		content: '';
		height: 1.5rem;
		background: transparent linear-gradient(180deg, #F2F3F800 0%, #fff 90%, #fff 100%) 0% 0% no-repeat padding-box;
	}

	&__no-results {
		display: flex;
		margin-left: 0.6rem;
		color: shade(@CL, 20%);
		font-size: 0.85rem;
		line-height: 1.1875rem;
	}
}

.show-more-button {
	font-size: 14px;
	line-height: 16px;
	margin: 3px 0 2px 10px;
	border-bottom: 1px solid @BB;
	color: @BB;
	text-decoration: none;
	font-weight: 500;

	&__chevron {
		margin-left: 3px;
		font-size: 13px;
	}
}
