
@import '../../../less/_mixins.less';
.blains-search-bar {
	background-color: tint(@CL, 90%);
	margin-bottom: 15px;

	.blains-input-wrapper {
		overflow: hidden;
		padding-bottom: 0;
		padding: 10px;

		.blains-input {
			background-color: #FFFFFF;
			border-radius: 5px 5px 0 0;
			border-bottom: 2px solid @LB;
			input {
				margin-left: 8px;
				font-size: 16px;
				color: shade(@CL, 60%);
				&::placeholder {
					color: shade(@CL, 16%);
				}
			}
		}
	}

	.icon-search {
		color: @LB;
		cursor: pointer;
		font-size: 16px;
	}
}
