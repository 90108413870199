
@import '~lessRoot/_mixins.less';

.saved-vehicle-metadata {
	display: grid;
	gap: 2px;
	font-size: 0.75rem;
	line-height: 1.33;
	text-transform: uppercase;

	&__row {
		display: flex;
		flex-direction: var(--metadata-row-alignment, row);
	}

	&__label {
		color: shade(@CL, 40%);
		margin-right: 4px;
	}

	&__value {
		font-weight: 500;
	}
}
