
@import "../../../../../less/_mixins.less";
.pagination {
	.show-pages {
		.button-reset();
		color: shade(@CL, 20%);

		.mobile-styles({&__more-label {display: none;}});
	}
}
.pagination-dropdown-wrapper {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	background: #fff;
	border-radius: 4px;
	max-height: 500px;
	overflow-y: scroll;
}
.pagination-dropdown-button {
	.button-reset();

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.6rem 0;
	font-size: 0.75rem;
	color: shade(@LB, 20%);

	border-bottom: 1px solid tint(@CL, 60%);
	&:last-of-type {
		border-bottom: none;
	}

	&:hover {
		background: tint(@CL, 90%);
	}

	&__label {
		display: inline-block;
		margin-left: 0.75rem;
	}

	&--current {
		color: shade(@CL, 40%);
		font-weight: bold;
	}
}

/deep/ .blain-dropdown,
/deep/ .dropdown-menu {
	background: transparent;
}

/deep/ .blain-dropdown.shadow,
/deep/ .blain-dropdown-arrow {
	box-shadow: 0 0 0 2px shade(@CL, 10%);
}
