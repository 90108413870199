
@import "../../less/_mixins.less";
.reviews-wrapper {
	display: flex;
	max-width: 300px;
}
.reviews-amount {
	font-size: 14px;
	margin-left: 8px;
}
.link-to-modal {
	font-size: 14px;
}
@media screen and (max-width: @mobile) {
	.review-stars {
		font-size: 15px!important;
	}
	.span-yellows {
		font-size: 15px;
	}
}
