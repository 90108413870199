
@import '~lessRoot/_mixins';

.change-store-contents {
	height: 100%;

	&__wrapper {
		background-color: #E5E7F1;
		height: 100%;
	}

	&__spinner-wrapper {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		width: var(--sidemenu-width);
		max-width: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 1;

		.sidemenu--right & {
			right: 0;
		}

		.sidemenu--left & {
			left: 0;
		}

		.spinner {
			position: absolute;
			inset: 50% auto auto 50%;
			transform: translate(-50%, -50%);
		}

		.spinner-loader {
			width: 60px;
		}
	}

	&__location-wrapper {
		padding: 15px 10px;
		background-color: white;

		.mobile-and-up-styles({
			padding: 20px 15px;
		});

		.horizontal-text-divider{
			margin: 4px 0px;

			&__text {
				color: #666;
				font: normal normal normal 0.75rem/1.08 Roboto;
				letter-spacing: 0.6px;
				margin: 6px;
			}

			&__line {
				background-color: #DFE0E0;
				height: 1px;
			}
		}
	}

	&__header {
		font: normal normal bold 1.125rem/1.39 Roboto !important;
		margin-bottom: 20px !important;

		.mobile-and-up-styles({
			font: normal normal bold 1.5rem/1.04 Roboto !important;
			margin-bottom: 30px !important;
		});
	}

	&__market-requirements {
		color: #4C5271;
		font-size: 1rem;
		font-weight: medium;
		line-height: 1.25;
		margin-bottom: 10px;
	}

	&__zip-wrapper {
		position: relative;
	}

	&__zip-text-field {
		margin-right: 94px;

		.mobile-and-up-styles({
			margin-right: 108px;
		});
	}

	&__zip-search-button {
		max-width: 84px !important;
		max-height: 40px !important;
		font-size: 0.875rem !important;
		line-height: 1.1 !important;
		padding: 10px 16px !important;
		position: absolute;
		top: 21px;
		right: 0;

		.mobile-and-up-styles({
			max-width: 98px !important;
			font-size: 1rem !important;
		});
	}

	&__location-button {
		max-width: 100% !important;
		font-size: 0.875rem !important;
		padding: 10px 16px !important;

		.mobile-and-up-styles({
			font-size: 1rem !important;
		});

	}

	&__location-error {
		font: normal normal 500 0.84rem/1.29 Roboto;
		color: #985800;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 10px;

		.icon {
			font: normal normal normal 1.313rem/1.19 Roboto;
			margin-right: 4px;
		}

		.mobile-and-up-styles({
			font-size: 0.875rem;
		});
	}

	&__grey-text {
		color: #636C94;
		font: normal normal 500 0.75rem/1.3 Roboto;

		.mobile-and-up-styles({
			font-size: 0.875rem;
		});
	}

	&__store-cards-wrapper {
		padding: 10px;

		.mobile-and-up-styles({
			padding: 20px 15px;
		});
	}

	&__stock-switch {
		margin-bottom: 12px;

		.mobile-and-up-styles({
			margin-bottom: 20px;
		});
	}

	&__current-store {
		margin-bottom: 15px;

		.mobile-and-up-styles({
			margin-bottom: 22px;
		});
	}

	&__nearby-stores {
		font: normal normal 500 0.78rem/1.3 Roboto;
		text-transform: uppercase;
		color: #31374D;
		border-bottom: 2px solid #96A1CE;
		display: inline-block;
		margin-bottom: 10px;

		.mobile-and-up-styles({
			font-size: 0.85rem;
		});
	}

	&__store-card {
		margin-bottom: 12px;
	}
}
