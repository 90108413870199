
@import "~lessRoot/_mixins.less";

.size-vehicle-fitment {
	padding: 15px 0 0 10px;

	.tablet-and-up-styles({
		padding: 0;
	});
}

