
@import "../../less/_mixins.less";

.select {
	display: inline-block;
	position: relative;
}

.select__options {
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 0;
	right: 0;
	border: 1px solid #ddd;
	padding: 0;
	margin: 0;
	width: 100%;
}

.select__option {
	box-sizing: border-box;
	appearance: none;
	display: block;
	width: 100%;
	padding: 0.6rem;
	border: none;
	background: #fff;
	text-align: left;
	cursor: pointer;

	&:hover {
		background: tint(@BB, 75%);
	}

	&[aria-selected="true"] {
		background: tint(@BB, 50%);
	}
}

.select__option + .select__option {
	border-top: 1px solid #ddd;
}
