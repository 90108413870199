
@import '../../../../less/_mixins.less';
.product-list-top {
	display: contents;
}

.filter-header {
	border-bottom: 4px solid tint(@CL, 60%);

	.mobile-styles({
		position: sticky;
		top: 0;
		z-index: 4;
	});

	.tablet-and-up-styles({
		border-bottom-width: 1px;
		border-bottom: 1px solid tint(@CL, 80%);
		padding-bottom: 0.75rem;
		padding-top: 10px;
	});

	&__tire-help {
		font-size: 14px;
		margin-top: 16px;

		.desktop-min-only-styles({
			margin-top: 20px;
		});
	}
}
