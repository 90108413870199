
@import "~lessRoot/_mixins.less";
@import "~lessRoot/_variables.less";

button {
	appearance: none;
	background: transparent;
	border: none;
	font-size: 14px;
	color: @BB;
	text-decoration: underline;
	flex-shrink: 0;
}
