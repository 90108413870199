
@import '../../../../less/_mixins.less';
	.search-facet-wrapper {
		background-color: #FFFFFF;
		margin-bottom: 25px;
	}

	.sfw-header {
		width: 100%;
		appearance: none;
		border: none;
		background: transparent;
		padding: 8px 0 0 10px;
		padding-bottom: 0.75rem;
	}

	.sfw-label {
		color: #191B26;
		font-size: 16px;
		min-height: 30px;
		font-weight: 500;
		display: flex;
		align-items: center;
		text-align: left;
	}

	.sfw-header {
		user-select: none;
		color: #191B26;
		font-size: 10px;
		justify-content: space-between;
		border-top: 1px solid tint(@CL, 80%);
		cursor: default;

		&.collapsable {
			cursor: pointer;
		}

		&.collapsed {
			.sfw-collapse {
				border-radius: 0 0 6px 6px;
			}
		}
	}

	.sfw-collapse {
		// background-color: lighten($light-blue, 80%);
		border-radius: 50%;
		color: @BB;
		height: 30px;
		width: 30px;
		font-size: 14px;
		align-items: center;
		.mobile-styles({
			margin-right: 5px;
		});
	}

	.chevdark {
		background-color: #FFFFFF;
	}
