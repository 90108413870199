
@import '../../../../less/_mixins.less';
.categories-wrapper {
	position: relative;
	background-color: tint(@CL, 90%);
	border-top: 4px solid tint(@CL, 80%);
	padding: 10px 10px 0 10px;
	margin-bottom: 25px;

	.title {
		display: block;
		color: shade(@CL, 30%);
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 0.5rem;
	}

	ul {
		margin-top: 6px;
	}

	.scrollable-container {
		.custom-scrollbar(@DB);

		padding: 1px 0;
		max-height: 250px;
		overflow-y: auto;

		&:after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			content: '';
			height: 17px;
			background: transparent linear-gradient(180deg, #F2F3F800 0%, #F2F3F8 90%, #F2F3F8 100%) 0% 0% no-repeat padding-box;
		}
	}

	.cls-wrapper {
		&:after {
			content: '';
			display: block;
			height: 17px;
			width: 100%;
			background: transparent;
		}
	}

	.cl-wrapper {
		&:not(:first-child) {
			margin-top: 12px;
		}
	}

	.category-link {
		color: @DB;
		font-size: 14px;
		font-weight: 400;
		text-decoration: none;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
			color: @DB;
		}
	}
}
