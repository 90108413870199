
@import "~lessRoot/_mixins.less";

.stock-status {
	margin-top: 10px;

	.in-stock {
		color: @AAEME;
	}
}
