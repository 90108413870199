
@import "~lessRoot/_mixins.less";

.tire-recommendations-banner {
	background-color: #E5E7F1;
	padding: 15px;

	h2 {
		color: #4C5271;
		text-transform: uppercase;
		font-size: 18px;
	}
	&__clear {
		color: #1D6C9D;
		padding-bottom: 2px;
		border-bottom: solid 1px #1D6C9D;
	}
	&__list {
		display: flex;
		width: 100%;
		li {
			display: flex;
			flex: 0 0 33.33332%;
			padding: 0 10px;
		}
	}
	&__product {
		border: solid 1px #CBD0E4;
		flex: 1 1 100%;
		margin-bottom: 0;
	}

	
	.dropdown-wrapper.price-alt-tip {
		display: block;
	}

	.styles-at(1420px, {
		padding-right: 0;

		&__clear {
			margin-right: 15px;
		}

		&__list {
			overflow-x: scroll;
			padding-bottom: 20px;
			> li {
				flex: 0 0 370px;
			}
		}

		// Hidden when the overflow is scrolling to prevent clipping
		.dropdown-wrapper.price-alt-tip {
			display: none;
		}
	});

	.tablet-styles({
		padding: 10px 0 10px 10px;
		&__clear {
			margin-right: 10px;
		}
		&__list {
			overflow-x: scroll;
			padding-bottom: 15px;
			> li {
				flex: 0 0 280px;
			}
		}
	});

}
