
@import '~lessRoot/_mixins';

.store-info-card {
	background-color: white;
	width: 100%;
	padding: 10px 8px;
	border-radius: 8px;
	position: relative;

	.mobile-and-up-styles({
		padding: 15px;
	});

	&__location-wrapper{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-bottom: 2px solid #ECEDF4;
		padding-bottom: 9px;
		margin-bottom: 10px;

		.mobile-and-up-styles({
			padding-bottom: 12px;
		});
	}

	&__city-name {
		font: normal normal 500 1rem/1.25 Roboto;
		margin-bottom: 0px;

		.mobile-and-up-styles({
			font-size: 1.25rem;
			line-height: 1.3;
		});
	}

	&__address {
		font: normal normal normal 0.75rem/1.2 Roboto;

		.mobile-and-up-styles({
			font-size: 0.875rem;
			line-height: 1.33;
		});
	}

	&__checkmark-icon {
		color: #007729;
		font: normal normal normal 0.875rem/1.36 Roboto;
	}

	&__your-store {
		color: #007729;
		font: normal normal bold 0.875rem/1.36 Roboto;
		text-transform: uppercase;
		margin-left: 3px;
	}

	&__make-your-store {
		padding: 8px 10px 7px 10px;
		color: #014A77;
		border: 2px solid #014A77;
		background-color: white;
		border-radius: 8px;
		text-transform: uppercase;
		font: normal normal bold 0.812rem/1 Roboto;
		max-height: 34px;

		.mobile-and-up-styles({
			font-size: 0.938rem;
			padding: 9px 12px 8px 12px;
			max-height: 38px;
		})
	}

	&__above-expand {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}

	&__make-your-store:hover {
		background-color: #014A77;
		color: white;
	}

	&__green-stock, &__red-stock, &__grey-stock {
		font: normal normal bold 0.875rem/1.29 Roboto;

		.mobile-and-up-styles({
			font-size: 1rem;
			line-height: 1.125;
		});
	}

	&__green-stock {
		color: #007729;
	}

	&__red-stock {
		color: #C11B2A;
	}

	&__grey-stock {
		color: #4B5274;
	}

	&__distance {
		font: normal normal bold 0.75rem/1.5 Roboto;

		.mobile-and-up-styles({
			font-size: 0.875rem;
			line-height: 1.29;
		});
	}

	&__LD-wrapper {
		display: flex;
		flex-direction: row;
		margin-top: 3px;
	}

	&__LD-icon {
		color: #717BA9;
		font-size: 0.875rem;
		margin-right: 2px;
	}

	&__LD-message {
		color: #4C5271;
		font: normal normal 500 0.75rem/1.33 Roboto;
	}

	&__hours-header {
		margin-top: 10px;
		font: normal normal 500 0.813rem/1.2 Roboto;
		text-transform: uppercase;
		color: #30374F;
		border-bottom: 2px solid #93A1D2;
		display: inline-block;
		margin-bottom: 8px;

		.mobile-and-up-styles({
			font-size: 0.938rem;
		});
	}

	&__hours-day {
		font: normal normal 500 0.875rem/1.36 Roboto;
		margin-right: 6px;
		margin-bottom: 2px;
	}

	&__hours-time {
		font: normal normal normal 0.875rem/1.36 Roboto;
		color: #4C5271;
	}

	&__phone-wrapper {
		display: flex;
		flex-direction: row;
		color: #195F8A;
		margin-top: 10px;
		margin-bottom: 15px;
		text-decoration: none;

		&:hover {
			color: #014A77;

			.store-info-card__phone-number {
				text-decoration: underline;
			}
		}
	}

	&__phone-icon {
		font-size: 0.875rem;
		line-height: 1.25;
		margin-right: 3px;
	}

	&__phone-number {
		font: normal normal 500 0.875rem/1.25 Roboto;
	}

	&__view-details {
		font-size: 0.75rem;

		.mobile-and-up-styles({
			font-size: 0.875rem;
		});
	}

	&__chevron-icon {
		font-size: 0.563rem;

		.mobile-and-up-styles({
			font-size: 0.625rem;
		});
	}
}

