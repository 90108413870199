
@import '../../../../less/_mixins.less';

.nested-checkbox-list {
	padding-left: 0.7rem;
	border-left: 4px solid tint(@CL, 80%);
}
.nested-checkbox-facet {
	margin-bottom: 0.8rem;
	color: @DB;
	font-size: 0.875rem;
	padding: 3px 0 0 0;
	line-height: 1;
}
