
@import "~lessRoot/_mixins.less";

.tire-product-summary {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	padding: 10px 0;
	margin: 0 10px;
	border-bottom: 1px solid tint(@CL, 60%);

	--tire-stat-value-line-height: 1.1;

	&--medium {
		--tire-header-brand-font-size: 16px;
		--tire-header-title-font-size: 22px;
		--tire-header-title-line-height: 1.36;

		--tire-stats-row-gap: 2px;
		--tire-stats-column-gap: 7px;
		--tire-stat-value-size: 28px;
		--tire-stat-value-line-height: 1.3;
		--tire-stat-label-size: 16px;

		grid-template-columns: 185fr 285fr;
		gap: 12px;
		padding: 15px 10px;
	}

	&--large {
		--tire-header-brand-font-size: 16px;
		--tire-header-title-font-size: 26px;
		--tire-header-title-line-height: 1.3;

		--tire-stats-row-gap: 6px;
		--tire-stats-column-gap: 7px;
		--tire-stat-value-size: 28px;
		--tire-stat-label-size: 16px;

		grid-template-columns: 230px 1fr 320px;
		gap: 15px;
		padding: 15px 10px;

		.styles-at(1200px,{
			grid-template-columns: 5fr 7fr;
		});
	}

	&__left {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__right {
		display: flex;
		flex-direction: column;
	}

	&--large &__right {
		display: contents;

		.styles-at(1200px,{
			display: flex;
			flex-direction: column;
		});
	}

	&__compare {
		justify-self: center;
		color: shade(@LB, 20%);
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 500;
		grid-column: 1;
		grid-row: 2;
	}

	&__size {
		font-weight: 500;
		font-size: 12px;
		line-height: 1.16;
		margin: 1px 0 3px 0;
	}

	&--medium &__size {
		margin-top: 4px;
		font-size: 1rem;
	}

	&--large &__size {
		font-size: 1rem;
	}

	&__see-details {
		display: none;
	}

	&--medium &__see-details {
		display: block;
		margin-top: 10px;
	}

	&--large &__see-details {
		display: block;
		margin-top: 15px;
	}

	&__meta {
		font-size: 12px;
		color: shade(@CL, 40%);
		line-height: 1.33;

		& > *:not(:last-child) {
			padding-right: 10px;
			margin-right: 10px;
			border-right: 1px solid currentColor;
		}
	}

	&__stats {
		margin: 6px 0 8px 0;
	}

	&__pricing-info {
		padding-left: 5px;
		border-left: 2px solid tint(@CL, 80%);
	}

	&__promo-container {
		display: grid;
		gap: 4px;
		margin-top: 8px;
	}

	&--large &__pricing-info {
		padding-left: 0;
		border-left: none;
	}

}
