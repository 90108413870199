
@import "~lessRoot/_mixins.less";

.tire-vehicle-fitment {
	padding: 15px 0 0 10px;

	.tablet-and-up-styles({
		padding: 0;
	});

	&__fit {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.35;
		color: @AAEME;
		margin-bottom: 6px;

		.tablet-and-up-styles({
			margin-left: 0px;
		});

		.icon {
			margin-right: 4px;
		}
	}

	&__help-wrapper {
		padding: 20px 0 14px 0;

		.tablet-and-up-styles({
			display: none;
		});
	}

	&__help {
		display: block;
		width: 100%;
		background: tint(@CL, 80%);
		text-align: center;
		padding: 7px 0;

		.link-button {
			font-size: 14px;
		}
	}
}
