
@import '../../../less/_mixins.less';
@checkboxSize: 20px;

.blains-checkbox {

	label {
		cursor: pointer;
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-start;
		font-size: 1em;
		position: relative;
		user-select: none;

		input {
			cursor: pointer;
			height: 0;
			opacity: 0;
			position: absolute;
			width: 0;
		}

		span {
			text-transform: capitalize;
			padding: 4px 0.25rem 0 0.5rem;
		}
	}

	.checkmark {
		position: relative;
		background-color: #ffffff;
		border: 1px solid @LB;
		border-radius: 3px;
		width: @checkboxSize;
		height: @checkboxSize;
		left: 0;
		flex-shrink: 0;
		top: 0;
		&.checked {
			border: 2px solid @LB;
		}
	}

	label input:checked ~ .checkmark {
		background-color: @LB;
		&.disabled {
			opacity: 0.4;
		}
	}

	input:focus ~ .checkmark,
	input:checked:focus ~ .checkmark {
		background-color: tint(@LB, 60%);
	}

	.checkmark::after {
		content: '';
		display: none;
		position: absolute;
	}

	label .checkmark.indeterminate::after {
		display: block;
		height: 3px;
		width: 12px;
		border-radius: 2px;
		background-color: @LB;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	label input:checked ~ .checkmark::after {
		display: block;
		border: solid #FFFFFF;
		border-width: 0 3px 3px 0;
		height: 12px;
		left: 5px;
		top: 1px;
		transform: rotate(45deg);
		width: 7px;
	}
}

.blains-checkbox.no-label {
	display: inline-block;

	label {
		padding-left: @checkboxSize + 4px;
	}

	.checkmark {
		left: 0;
		top: 0;
	}
}
