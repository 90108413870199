
@import "~lessRoot/_mixins.less";
.tire-header {

	&__brand {
		text-decoration: none;
		font-size: var(--tire-header-brand-font-size, 12px);
		line-height: 1.3125;
		font-weight: bold;
		color: #666666;
	}

	&__title-link {
		text-decoration: none;
		color: black;
	}

	&__title {
		font-size: var(--tire-header-title-font-size, 18px);
		line-height: var(--tire-header-title-line-height, 1.11);
		font-weight: bold;
		margin: 0;
		display: block;
	}
}
