
@import '../../../../less/_mixins.less';

.facet-app {

	.facet-sidemenu__header {
		display: flex;
		align-items: center;
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		background: #fff;
		z-index: 1;
		padding: 0.6rem;
		border-bottom: 4px solid tint(@CL, 60%);
	}

	.facet-sidemenu__header-text {
		margin-bottom: 0;
		font-weight: normal;
		font-size: 1rem;
		margin-right: auto;
	}

	.facet-sidemenu__header-button {
		appearance: none;
		background: tint(@LB, 10%);
		color: #fff;
		font-size: 0.9rem;
		padding: 0.5rem 0.8rem;
		border-radius: 6px;
		border: 2px solid rgba(255, 255, 255, 30%);
	}

	.mobile-styles({
		#wrapper #content {
			padding: 0;

			.list-container {
				padding: 0 10px 10px 10px;
			}

			.product-list-head  {
				background: tint(@CL, 90%);
			}

			#breadcrumbs {
				background: tint(@CL, 90%);
				padding: 10px;
				margin-bottom: 0;
				.breadcrumb {
					margin-bottom: 0;
				}
			}

			.list-container {
				margin: 0;
			}

		}
	});
}
