
@import '~lessRoot/_mixins.less';

a.tire-product-card {
	display: block;
	text-decoration: none;
	overflow: visible;
}

.tpc {
	--tire-header-brand-font-size: 14px;
	--tire-header-title-font-size: 16px;
	--tire-header-title-line-height: 1.3;
	--tire-stat-value-line-height: 1.1;

	color: #000;
	display: block;
	padding: 10px 15px 15px;
	margin-bottom: 15px;

	&__header {
		border-bottom: solid 1px #CCC;
		padding-bottom: 9px;
		min-height: 35px;
	}

	&__season {
		background-color: @AAEME;
		color: #FFFFFF;
		line-height: 0.8em;
		padding: 6px 8px;
		font-size: 14px;
		border-radius: 0 6px 6px 0;
		text-transform: uppercase;
	}

	&__image {
		margin-right: 15px;
		flex: 0 0 130px;
		img {
			max-width: 100%;
		}
	}

	&__brand {
		color: #4C5271;
	}

	&__title {
		flex: 1 1 auto;

		.tire-header__title-link:after {
			content: '';
			position: absolute;
			inset: 0;
		}
	}

	&__stats {
		margin: 7px 0;
	}

	.price-comment {
		font-size: 0.35em !important;
	}

	&.small {
		--tire-header-brand-font-size: 12px;
		--tire-header-title-font-size: 14px;
		--tire-header-title-line-height: 1.3;
		--tire-stat-value-line-height: 1.1;

		padding: 10px;

		.tpc__header {
			min-height: 30px;
		}

		.tpc__image {
			flex: 0 0 80px;
		}

		.tpc__season {
			line-height: 1em;
			padding: 4px 6px;
			font-size: 12px;
		}
	}

	

	&.blain-hover-card {
		overflow: visible;

		.blain-dropdown {
			left:-196px !important;
		}

		.blain-dropdown-arrow {
			left: 200px !important;
		}
	}

	.styles-at(380px, {

		&__product {
			flex-direction: column;
		}

		&.blain-hover-card {
			.blain-dropdown {
				left: -95px !important;
			}

			.blain-dropdown-arrow {
				left: 99px !important;
			}
		}
	})
}
