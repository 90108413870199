
@import "~lessRoot/_mixins.less";

.radiobutton-facet {
	margin-bottom: 0.7rem;
	color: #01305F;
	font-size: 14px;
	padding: 3px 0 0 0;
	line-height: 1.25;
	justify-content: space-between;

	.facet-total {
		margin: 4px 0 0 4px;
		color: #191B26;
	}
}
