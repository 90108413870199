
@import "../../../../less/_mixins.less";
.product-flag {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 5px 10px;
	font-size: 0.75rem;
	font-weight: 500;
	letter-spacing: 0.05rem;
	line-height: 1;
	color: #fff;
	text-transform: uppercase;
	border-radius: 0px 6px 6px 0px;
	white-space: nowrap;

	&--red {
		background-color: #B12F31;
	}

	&--blue {
		background-color: @BB;
	}

	&--left {
		border-radius: 6px 0 0 6px;
	}

	.icon {
		font-size: 15px;
		margin-right: 3px;
	}
}
