
@import '../../../../less/_mixins.less';
.radiogroup-facet-container {
	position: relative;
	padding: 0 0.625rem;

	.tablet-and-up-styles({
		padding: 0 0.625rem;
	});
}
