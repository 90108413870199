
@import "~lessRoot/_mixins.less";

.tire-compare-header {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 50px;

	.tablet-and-up-styles({
		height: 83px;
		max-height: 83px;
		transition: max-height 0.25s ease-in-out;

		&--show {
			max-height: 60px;
		}
	});

	.desktop-min-only-styles({
		height: auto;
	});
}

.tire-compare-footer {
	margin: 0 auto;
	padding: 0 0 20px 0;
	display: inline-block;
	width: 650px;
	max-width: 100%;

	.desktop-min-only-styles({
		padding: 12px 0 58px 0;
		max-width: 1252px;
		display: block;
		width: 100%;
	});

	&__inner {
		.desktop-min-only-styles({
			display: flex;
			align-items: center;
			max-width: 1252px;
			margin: 0 auto;
			padding: 0 15px;
		});
	}

	&__compare.ui-btn {
		max-width: 260px;
		margin: 0 auto;
		font-size: 1rem;

		.desktop-min-only-styles({
			margin: 0;
		});
	}

	&__compare-wrapper {
		display: flex;
		justify-content: flex-end;
		flex: 0 1 325px;
		.desktop-min-only-styles({
			padding-left: 3rem;
		});
	}
}

.tire-compare-footer-tires-wrapper {
	display: flex;
	overflow-x: scroll;
	margin-bottom: 15px;
	flex: 1;
	&::after {
		content: '';
		width: 25px;
		flex-shrink: 0;
	}

	.tablet-and-up-styles({
		overflow-x: visible;
		&::after {
			display: none;
		}
	});

	.desktop-min-only-styles({
		margin-bottom: 0;
	});
}

.tire-compare-footer-title {
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1.35;
	margin: auto auto 7px auto;
	padding-left: 25px;
	width: 100%;
	max-width: 650px;

	.tablet-and-up-styles({
		font-size: 0.875rem;
		padding-left: 0;
		font-size: 1rem;
		margin: auto auto 10px auto;
	});

	.desktop-min-only-styles({
		padding-left: 15px;
		padding-top: 21px;
		font-size: 1.125rem;
		margin-bottom: 0;
		max-width: 1252px;
	});
}

.tire-compare-footer-tires {
	display: grid;
	grid-template-columns: repeat(3, minmax(200px, 209px));
	gap: 11px;
	margin: 0 auto;
	padding: 0 0 15px 25px;

	.tablet-and-up-styles({
		padding: 0;
	});

	.desktop-min-only-styles({
		grid-template-columns: repeat(3, minmax(230px, 1fr));
		gap: 2rem; // not supported well enough yet to use with flexbox
		flex-grow: 1;

		margin-left: 0;
	});
}

.tire-compare-footer-tire {
	display: flex;
	align-items: center;
	border-style: solid;
	position: relative;
	padding: 8px 30px 8px 0;
	border-radius: 8px;
	border: 1px solid tint(@CL, 20%);
	overflow: hidden;
	height: 100%;
	background-color: white;
	margin: 1px; // accounts for difference in border size of empty tire to prevent layout shift

	&__image-wrapper {
		flex-shrink: 0;
		width: 79px;
		margin-left: -25px;

		.desktop-min-only-styles({
			width: 128px;
			margin-left: -40px;
		});
	}

	&__image {
		padding-bottom: 100%;
		position: relative;
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__remove {
		position: absolute;
		top: 10px;
		right: 10px;
		color: @LB;
		font-size: 1rem;
	}

	&__brand {
		font-size: 0.75rem;
		color: #666666;
		font-weight: bold;
		line-height: 1.3125;

		.desktop-min-only-styles({
			font-size: 1rem;
		});
	}

	&__name {
		font-size: 0.8125rem;
		font-weight: bold;
		line-height: 1.5;
		color: black;
		margin-bottom: 9px;

		.desktop-min-only-styles({
			font-size: 1.125rem;
			line-height: 1.11111;
		});
	}

	&__map {
		font-size: 0.75rem;
		font-weight: 500;
		line-height: 1.2;

		.desktop-min-only-styles({
			font-size: 1rem;
		});
	}

	&__price {
		font-size: 1.875rem;
		font-weight: 500;
	}
}

.tire-compare-footer-dummy-tire {
	border: 2px dashed tint(@CL, 20%);
	display: flex;
	align-items: center;
	position: relative;
	padding: 8px 30px 8px 0;
	overflow: hidden;
	border-radius: 8px;

	&__image-wrapper {
		flex-shrink: 0;
		width: 79px;
		margin-left: -20px;

		.desktop-min-only-styles({
			width: 128px;
			margin-left: -33px;
		});
	}

	&__image {
		padding-bottom: 100%;
		position: relative;
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.tire-compare-footer-wrapper {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 11;
	background-color: #f2f3f8;
	width: 100%;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.153);
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.tire-compare-slide-up-enter, .tire-compare-slide-up-leave-to {
	transform: translateY(100%);
}

.tire-compare-slide-up-enter-to {
	transform: translateY(0);
}

.tire-compare-slide-up-enter-active, .tire-compare-slide-up-leave-active {
	transition: all 0.3s ease;
}

.tire-compare-toggle {
	.button-reset();
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	.tablet-and-up-styles({
		padding: 14px;
		gap: 4px;
	});

	.desktop-min-only-styles({
		display: none;
	});

	&__text {
		font-size: 0.75rem;
		line-height: 1.33;
		font-weight: 500;
		color: @DB;
		transition: opacity 0.25s ease-in-out;
		margin: auto auto 8px auto;

		.tablet-and-up-styles({
			font-size: 1rem;
			margin: auto auto 14px auto;
		});
	}

	&[aria-expanded=true] &__text {
		opacity: 0;
		pointer-events: none;
	}

	.icon {
		display: block;
		color: @LB;
		font-size: 1.25rem;
		transition: transform 0.25s ease-in-out;

		.tablet-and-up-styles({
			font-size: 1.875rem;
		});
	}

	&[aria-expanded=true] .icon {
		transform: rotate(180deg);
	}
}

