
@import "../../../../../less/_mixins.less";
.page-number-list {
	display: flex;
	justify-content: center;
	min-width: 350px;
}

.page-number-container {
	display: flex;
	justify-content: center;
	margin: 0 0.25rem;
	flex: 1 1 0;
	max-width: 60px;
}

.page-number {
	box-sizing: border-box;
	border-width: 1px;
	padding: 9px 0 !important;
	&:hover {
		padding: 9px 0;
	}
}

.current-page {
	color: shade(@CL, 60%);
	border: none;
	border-radius: 0;
	border-bottom: 2px solid @CL;
	padding: 11px 0;
	&:hover {
		border: none;
		border-bottom: 2px solid @CL;
		color: shade(@CL, 60%);
		cursor: default;
	}

	.desktop-min-only-styles({
		padding: 11px 0;
	});
}

.dots {
	padding: 11px 0;
	color: shade(@CL, 20%);
	font-weight: 500;

	.desktop-min-only-styles({
		padding: 11px 0;
	});
}
