
@import '../../../../less/_mixins.less';

.filter-header-bottom {
	position: relative;
	width: 100%;
	padding-top: 0.4rem;
	background: #fff;

	.tablet-and-up-styles({
		padding-top: 0rem;
	});

	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 1.5rem;
		background: transparent linear-gradient(90deg, #F2F3F800 0%, #FFF 90%, #FFF 100%) 0% 0% no-repeat padding-box;
	}
}
.filter-header-bottom__inner {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	overflow-x: scroll;
	width: 100%;
	padding-right: 0.6rem;
	scrollbar-width: thin;

	/**
	 * Adds spacing to end of overflow container
	 */
	&:after {
		content: '';
		width: 1px;
		height: 1px;
		flex-shrink: 0;
	}
}
.clear-all {
	display: block;
	padding: 0 0.75rem;
}
/deep/ .selected-facet {
	padding: 10px 0;
}
