
@import '~lessRoot/_mixins.less';
.blain-hover-card {
	background-color: #FFFFFF;
	overflow: hidden;
	position: relative;
	&::after {
		background-color: tint(@CL, 60%);
		content: "";
		position: absolute;
		height: 1px;
		right: 0; bottom: 0; left: 0;
		transition: height 0.2s ease-in;
	}

	&:hover {
		&::after {
			height: 4px;
		}
	}
}
