
@import "~lessRoot/_mixins.less";
.tire-stats {
	display: grid;
	row-gap: var(--tire-stats-row-gap, 2px);

	.tire-stat {
		display: flex;
		align-items: center;
		color: shade(@CL, 60%);

		&__value {
			font-size: var(--tire-stat-value-size, 16px);
			line-height: var(--tire-stat-value-line-height, 1.3);
			text-transform: lowercase;
			font-weight: 500;
		}

		&__label {
			font-size: var(--tire-stat-label-size, 12px);
			font-family: 'Roboto Condensed';
			line-height: 1.17;
			margin-left: var(--tire-stats-column-gap, 4px);
			text-transform: uppercase;
		}
	}
}

