
.blains-form-control {

	.blains-input-wrapper {
		// border-bottom: solid 3px $light-blue;
		padding-bottom: 3px;
	}

	.blains-label {
		color: #A8A29E;
		display: inline-block;
		font-size: 1.2rem;
		margin-bottom: 3px;
		margin-right: 0.5rem;
		text-transform: uppercase;
	}

	.ef-optional {
		text-transform: none;
	}
}

.blains-input {
	// background-color: lighten($light-blue, 90%);
	overflow: visible;
	padding: 0.6rem;

	input,
	select,
	textarea {
		appearance: none;
		background-color: transparent;
		border: none;
		display: block;
		font-size: 1.6rem;
		outline: none;
		width: 100%;

		&::placeholder {
			color: #AAAAAA;
		}
	}

	&.readonly {
		background-color: transparent;

		input,
		select,
		textarea {
			// color: $dark-blue;
		}
	}
}

.blains-field-icon {
	font-size: 1.6rem;
}
